import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "react-bootstrap/Button"
import { Col, Row } from "react-bootstrap"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const SolutionTemplate = ({ data, pageContext, location }) => {
  const { solution } = data;
  return (
    <Layout>
      <SEO
        title={solution.name}
        description={solution.description.description}
      />

      <article>
        <section>
          <Row>
            <Col md={5} lg={4} className='text-md-right'>
              <header>
                <h1>{solution.name}</h1>
              </header>
            </Col>
            <Col xs={12} sm={12} md={7} lg={6} className='pt-md-1'>
              {renderRichText(solution.body)}

              <Link to={`/solutions`}>
                <Button as="span" variant='primary' size='sm'>All Solutions&hellip;</Button>
              </Link>
            </Col>
          </Row>
        </section>
      </article>
    </Layout>
  );
}

export default SolutionTemplate;

export const pageQuery = graphql`
  query SolutionBySlug($slug: String!) {
    solution: contentfulSolution(slug: {eq: $slug}) {
      name
      description {
        description
      }
      body {
        raw
      }
    }
  }
`
